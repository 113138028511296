import Image from 'next/image';
import Socials from 'components/common/markup/Socials';

import css from './Footer.module.scss';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <section id="footer">
      <div className={css.socialsMobile}>
        <Socials />
      </div>
      <div className={css.inner}>
        <div className={css.col}>
          <div className={css.copyright}>
            © 2015-{currentYear} Российское общество «Знание»
          </div>
          <div className={css.socials}>
            <Socials />
          </div>
        </div>
        <div className={css.col}>
          <a
            href="https://www.znanierussia.ru/"
            rel="noreferrer"
            target="_blank"
          >
            <Image
              src="/roz-logo-sec.svg"
              width={100}
              height={38}
              alt="логотип РОЗ"
            />
          </a>
        </div>
      </div>
    </section>
  );
}
