import { useEffect } from 'react';
import Head from 'next/head';
import TagManager from 'react-gtm-module';

import FavIcons from 'components/common/markup/FavIcons';

import { IS_PRODUCTION } from 'utils/envs';

import config from 'json/config.json';

export type MetaItem = {
  title: string;
  description: string;
};

interface IProps {
  metaData: MetaItem;
  shareImage?: string;
}

export default function AppHead({ metaData, shareImage }: IProps) {
  const { title, description } = metaData;

  useEffect(() => {
    if (IS_PRODUCTION) {
      TagManager.initialize({ gtmId: config.gtagId });
    }
  }, []);

  return (
    <Head>
      <title>{title}</title>
      <FavIcons />
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="yandex-verification" content="adddda9d001da534" />
      {shareImage && <meta name="og:image" content={shareImage} />}
    </Head>
  );
}

AppHead.defaultProps = {
  shareImage: '',
};
