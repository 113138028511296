export { fadeUp } from './fadeUp';
export { fadeLeft } from './fadeLeft';
export { fadeRight } from './fadeRight';

export const fadeVars = {
  visible: (i = 0) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.3, duration: 0.5, staggerChildren: 0.05 },
  }),
  hidden: { opacity: 0, y: 100 },
};

export const fadeItemVars = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export default {};
