import Image from 'next/image';

import css from './Socials.module.scss';

type TSocialItem = {
  id: number;
  type: string;
  url: string;
};

const socials: TSocialItem[] = [
  {
    id: 1,
    type: 'vk',
    url: 'https://vk.com/znanierussia',
  },
  {
    id: 2,
    type: 'youtube',
    url: 'https://www.youtube.com/c/РоссийскоеобществоЗнание',
  },
  {
    id: 3,
    type: 'telegram',
    url: 'https://t.me/Znanie_Russia',
  },
];

export default function Socials() {
  return (
    <div className={css.block}>
      {socials.map((item) => (
        <a
          href={item.url}
          target="_blank"
          key={item.id}
          className={css.item}
          rel="noreferrer"
        >
          <Image
            src={`/icons/${item.type}.svg`}
            width={16}
            height={16}
            alt={item.type}
          />
        </a>
      ))}
    </div>
  );
}
