import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import cn from 'classnames';
import { motion } from 'framer-motion';

import { fadeVars } from 'utils/animations';
import { IS_DEVELOPMENT } from 'utils/envs';

import type { TMenuItem } from 'types/menu';

import config from 'json/config.json';

import css from './Navbar.module.scss';

interface IProps {
  menuItems?: TMenuItem[];
  rootUrl?: string;
  desktopLogo?: boolean;
  customContent?(): JSX.Element;
  className?: string;
  logoClassName?: string;
  logo?: string;
}

function Navbar({
  menuItems,
  rootUrl,
  desktopLogo,
  customContent,
  className,
  logoClassName,
  logo,
}: IProps) {
  const [show, setShow] = useState(false);

  const openMobileMenu = () => !show && setShow(true);

  const closeMobileMenu = () => show && setShow(false);

  return (
    <div className={cn(css.navbar, className)}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeVars}
        className={cn(
          css.logo,
          desktopLogo && css.logoOnlyMobile,
          logoClassName && logoClassName
        )}
      >
        <Link href="/">
          <a>
            <Image
              src={logo || '/roz-logo.svg'}
              alt="логотип РОЗ"
              width="100%"
              height="100%"
              layout="responsive"
              quality="100"
              unoptimized={config.disableImgOptim}
            />
          </a>
        </Link>
      </motion.div>
      {desktopLogo && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeVars}
          className={css.desktopLogo}
        >
          <Link href="/">
            <a>
              <Image
                src="/roz-logo-sec.svg"
                alt="логотип РОЗ"
                width={150}
                height={80}
                quality="100"
                unoptimized={config.disableImgOptim}
              />
            </a>
          </Link>
        </motion.div>
      )}
      {menuItems.length > 0 && (
        <>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeVars}
            className={cn(css.nav, show && css.show)}
          >
            <button
              type="button"
              className={css.close}
              onClick={closeMobileMenu}
            >
              ✕
            </button>
            {menuItems.map((item, index) => {
              const externalPattern = /^((https?):\/\/)/;
              const url = externalPattern.test(item.url)
                ? item.url
                : `${IS_DEVELOPMENT ? rootUrl : ''}${item.url}`;

              return (
                <Link key={item.id} href={url}>
                  <a
                    tabIndex={index + 1}
                    role="menuitem"
                    onClick={closeMobileMenu}
                    onKeyDown={closeMobileMenu}
                  >
                    {item.label}
                  </a>
                </Link>
              );
            })}
          </motion.div>
          <motion.div
            initial="hidden"
            animate={{ x: [100, 0] }}
            transition={{ duration: 0.3 }}
            className={css.hamburger}
          >
            <button type="button" onClick={openMobileMenu}>
              <Image
                src="/icons/hamburger.svg"
                width={17}
                height={13}
                alt=""
                unoptimized={config.disableImgOptim}
              />
            </button>
          </motion.div>
        </>
      )}
      {customContent && (
        <motion.div
          className={css.customContent}
          initial="hidden"
          animate="visible"
          variants={fadeVars}
        >
          {customContent()}
        </motion.div>
      )}
    </div>
  );
}

Navbar.defaultProps = {
  menuItems: [],
  rootUrl: '',
  desktopLogo: false,
  customContent: null,
  className: '',
  logoClassName: '',
  logo: null,
};

export default Navbar;
