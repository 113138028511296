import { Variant, Variants } from 'framer-motion';
import { transition } from './constants/transition';

export const fadeLeft: Variants = {
  initial: {
    opacity: 0,
    x: 100,
  } as Variant,
  animate: (i) => ({
    opacity: 1,
    x: 0,
    ...transition(i),
  }),
};
